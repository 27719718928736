import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Pipe({
  name: 'translate'
})

export class TranslatePipe implements PipeTransform {

  data: any;
  constructor(private localizationService: LocalizationService) {
    this.localizationService.data.subscribe((d) => {
      this.data = d;
    });
  }

  transform(value: any, args?: any): any {
    let valueData = this.data;
    value?.split('.')?.forEach((d: any) => {
      if (d in valueData) {
        valueData = valueData![d];
      }
      else {
        console.log(value, ' text not found in local JSON');
      }
    });
    if (typeof (valueData) != 'object') {
      return valueData;
    } else {
      return value;
    }
  }

}
