import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'button[cvTransparentButton]'
})

export class CvTransparentBtnDirective {

  constructor(
    renderer2: Renderer2,
    el: ElementRef<HTMLButtonElement>
  ) {
    renderer2.addClass(el.nativeElement, 'theme-btn');
    renderer2.addClass(el.nativeElement, 'transparent-btn');
  }
}
