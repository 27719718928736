<div class="custom-time-picker form-group icon right border-select">
  <img *ngIf="!icon" class="icon" src="assets/images/clock.svg" alt="">
  <img *ngIf="icon" class="icon" src="assets/images/calender-blue2.svg" alt="">
  <!-- <mat-select placeholder="HH" [(ngModel)]="selectedHour" (selectionChange)="onTimeSelect()">
    <mat-option [value]="item" *ngFor="let item of hours">{{item}}</mat-option>
  </mat-select>
  <span class="colon">:</span> -->
  <mat-select placeholder="MM" [(ngModel)]="selectedMinute" (selectionChange)="onTimeSelect()">
    <mat-option [value]="min" *ngFor="let min of minutes">{{min}}</mat-option>
  </mat-select>
  <!-- <mat-select placeholder="{{!placeholder?'A':placeholder}}" [(ngModel)]="selectedPeriod"
    (selectionChange)="onTimeSelect()">
    <mat-option value="am">AM</mat-option>
    <mat-option value="pm">PM</mat-option>
  </mat-select> -->
  <img src="assets/images/error.png" alt="" class="errorImg" *ngIf="error" matTooltipPosition="before"
    [matTooltip]="error">
</div>