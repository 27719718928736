import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { ValidatorsService } from '../../ui-modules/cv-form-field/services/validation.service';

@Component({
  selector: 'app-time-picker1',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    }
  ]
})
export class TimePickerComponent implements OnInit {
  @Input('error') errors?: any;

  hours: string[] = new Array(13).fill(0, 0).map((i, index) => index.toString().length == 1 ? `0${index}` : `${index}`);
  minutes: string[] = new Array(60).fill(0, 0).map((i, index) => index.toString().length == 1 ? `0${index}` : `${index}`);
  selectedHour?: string;
  selectedMinute?: string = 'MM';
  selectedPeriod: string = '';
  selectedTime?: string;
  @Output() selectHour = new EventEmitter<any>();
  @Output() selectMinute = new EventEmitter<any>();
  @Input() icon?: boolean;
  @Input() formatTrue?: boolean;
  @Input() placeholder?: string;

  constructor() { }

  ngOnInit() { }

  onTimeSelect() {
    if (typeof (this.selectedHour) == 'undefined') {
      this.selectedHour = '00';
    }
    this.value = `${this.selectedHour}:${this.selectedMinute || '00'} ${this.selectedPeriod}`
    if (this.formatTrue) {
      this.value = moment(this.value, 'hh:mm').format('hh:mm');
    }

  }

  onChange: any = () => { }
  onTouch: any = () => { }
  private val = "";
  get value() {
    return this.val;
  } // this is the updated value that the class accesses
  set value(val) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val;
    this.selectedHour = moment(val, 'hh:mm:ss').get('hour').toString();
    if (this.selectedHour.length == 1) {
      this.selectedHour = `0${this.selectedHour}`;
    }
    if (moment(val, 'hh:mm:ss').get('hour') == 0) {
      this.selectedHour = '00';
    }
    if (moment(val, 'hh:mm:ss').get('hour') > 11) {
      this.selectedPeriod = 'pm'
    } else {
      this.selectedPeriod = 'am'
    }
    this.selectedMinute = moment(val, 'hh:mm:ss').get('minutes').toString();
    if (this.selectedMinute == 'NaN') {
      this.selectedMinute = '00'
    }
    if (this.selectedMinute.length == 1) {
      this.selectedMinute = `0${this.selectedMinute}`;
    }
    this.onChange(val)
    this.onTouch(val);
    this.selectHour.emit(this.selectedHour);
    this.selectMinute.emit(this.selectedMinute);

  }

  writeValue(value: any) {
    this.value = value
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  get error() {
    if (this.errors?.required == true) {
      return 'Please select time'
    }
    return ValidatorsService.error(this.errors) || '';
  }

}
