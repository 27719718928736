import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IApi } from "../http-handler/common/contracts/api";
import { GenericApi } from "../http-handler/common/generic-api";
import { EventCategory } from "../models/event-category.model";

@Injectable({
  providedIn: "root",
})
export class EventCategoryService {
  categories: IApi<EventCategory> = new GenericApi<EventCategory>(
    "eventCategories",
    this._http
  );
  constructor(private _http: HttpClient) {}
}
