import { Component, Inject, OnInit } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

@Component({
  selector: "app-toster",
  template: `
    <div class="toster">
      <span class="txt14-white-rgulr">{{ data.text | translate }}</span>
      <div class="pl-3 center">
        <mat-icon (click)="tosterRef.closeWithAction()"></mat-icon>
      </div>
    </div>
  `,
  styles: [
    `
      mat-icon {
        cursor: pointer;
      }
      .toster {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    `,
  ],
})
export class TosterComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: TosterData,
    public tosterRef: MatSnackBarRef<TosterComponent>
  ) {}

  ngOnInit() {}
}

interface TosterData {
  text: string;
}
