import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { ValidatorsService } from "../services/validation.service";

@Directive({
  selector: "[cvInput]",
  exportAs: "cvInput",
})
export class InputFieldDirective {
  @Input() hintText: string = "";
  @Input() name: string = "Field";
  @Input() submitted?: boolean;
  @Input() isNumber: boolean = false;
  @Input() isPrice: boolean = false;
  @Input() isPriceEur: boolean = false;
  @Input() errors: any;

  constructor(
    private _el: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    private _ngControl?: NgControl
  ) {}

  get element() {
    return this._el.nativeElement;
  }

  get control() {
    return this._ngControl?.control;
  }

  @HostBinding("attr.has-value") get hasValue() {
    return !!this.control?.value;
  }

  get error() {
    return ValidatorsService.error(
      this.errors || this.control?.errors,
      this.name
    );
  }

  @HostBinding("class.input-field") private readonly mainClass?: boolean = true;

  @HostListener("keypress", ["$event"]) keypress(e: KeyboardEvent) {
    this.isNumberValidation(e);
  }

  @HostListener("input", ["$event"]) input(e: any) {
    // if (this.isPriceEur) {
    //   let v = `${e.target.value}`;
    //   let match = v.replace(/\,+/g, '.');
    //   console.log(match);
    //   if (!match) return;
    //   // this._ngControl?.control?.setValue(+match);
    //   e.target.value = v;
    // }
  }

  isNumberValidation(e: KeyboardEvent) {
    // Dollar value
    if (this.isPrice) {
      const value: string = (e.target as any).value;
      if (value.includes(".") && e.keyCode == 46) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 46) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
    // Euro
    if (this.isPriceEur) {
      const value: string = (e.target as any).value;
      if (value.includes(",") && e.keyCode == 44) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 44) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
    if (this.isNumber) {
      if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }
}
