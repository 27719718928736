import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';
import { InternetConnectionService } from 'src/app/services/internet-connection.service';

@Component({
  selector: 'button[orangeGradientBtn]',
  template: `
    <ng-content></ng-content>
    <mat-progress-spinner
      *ngIf="isLoading"
        [diameter]="20"
        [strokeWidth]="1.5"
        mode="indeterminate">
    </mat-progress-spinner>
  `,
  styles: [``]
})
export class OrangeGradientBtnComponent implements OnInit {

  @Input() orange: boolean = false;
  @Input() isLoading: boolean | null = null;
  @Input("disabled") disable?: boolean = false;


  constructor(
    private _connectionService: InternetConnectionService,
    renderer2: Renderer2,
    private el: ElementRef<HTMLButtonElement>
  ) {

    renderer2.addClass(el.nativeElement, 'theme-btn');
    renderer2.addClass(el.nativeElement, 'orange-btn');
    renderer2.addClass(el.nativeElement, 'orange-gradient-btn');

  }

  ngOnInit() {
  }

  @HostBinding('class.isLoading') get loadingClass() { return this.isLoading };

  @HostBinding('disabled') get disabled() {
    return (this.isLoading || !this._connectionService.isConnected || this.disable);
  };

}
