export enum AUTH_ROUTES {
  MAIN = "auth",
  LOGIN = "login",
  REGISTER = "register",
  EMAIL_COMFIRMED = "email-comfirmed",
  FORGOT_PASSWORD = "forgot-password",
  RESET_PASSWORD = "reset-password",
  USER_ROLE = "type",
  USER_INFO = "user-info",
}

export enum AUTH_NAVIGATIONS {
  MAIN = "/public",
  LOGIN = `/auth/login`,
  REGISTER_F = "/auth/register",
  REGISTER_S = "/auth/register",
  EMAIL_COMFIRMED = "/auth/email-comfirmed/",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/reset-password/",
  USER_ROLE = "/auth/type/",
  USER_INFO = "/auth/user-info/",
  SUBSTITUTE_RESET_PASSWORD = "/auth/event-substitute/reset-password/",
}
