<div
  class="form-field"
  [attr.has-error]="input?.control?.touched && input?.control?.invalid"
>
  <div class="prefix" #prefix></div>
  <div class="inner-fix" #innerFix>
    <label hidden #label></label>
  </div>
  <div class="append-after" #appendAfter></div>
</div>
<ng-content></ng-content>
<div
  class="hint-text"
  *ngIf="(!input?.error || !input?.control?.touched) && input?.hintText"
>
  {{ input?.hintText }}
</div>
<div
  class="error"
  *ngIf="input?.control?.touched && input?.control?.invalid && input?.error"
>
  {{ input?.error }}
</div>
