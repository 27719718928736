import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TokenInterceptor } from "./token.interceptor";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ConnectionStatusComponent } from "./components/connection-status/connection-status.component";
import { environment } from "src/environments/environment";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "angularx-social-login";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";
import { SETTINGS as AUTH_SETTINGS } from "@angular/fire/compat/auth";
import { TosterComponent } from "./components/toster/toster.component";
import { MatIconModule } from "@angular/material/icon";
import { CommonSharedModule } from "./modules/common-shared/common-shared.module";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireModule } from "@angular/fire/compat";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { ServiceWorkerModule } from "@angular/service-worker";
import { UserService } from "./services/user.service";
import { MatDialogModule } from "@angular/material/dialog";
import { CvFormFieldModule } from "./modules/ui-modules/cv-form-field/cv-form-field.module";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { SearchLocationAutocompleteModule } from "./modules/ui-modules/search-location-autocomplete/search-location-autocomplete.module";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: "#000",
    },
    button: {
      background: "#fe693c",
      text: "#fff",
    },
  },
  theme: "edgeless",
  type: "info",
  content: {
    message:
      "This website uses cookies to ensure you get the best experience on our website.",
    allow: "Allow cookies",
    deny: "Refuse cookies",
    link: "Learn more",
    href: "https://couchvibes.com/imprint",
    policy: "Cookie Policy",
  },
};

const config: SocketIoConfig = {
  url: environment.socketUrl, // socket server url;
  options: {
    transports: ["websocket"],
  },
};

@NgModule({
  declarations: [AppComponent, ConnectionStatusComponent, TosterComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonSharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    SocialLoginModule,
    MatIconModule,
    SocketIoModule.forRoot(config),
    NgcCookieConsentModule.forRoot(cookieConfig),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: true,
    }),
    MatDialogModule,
    CvFormFieldModule.forRoot({ isShadow: false }),
    MatNativeDateModule,
    MatDatepickerModule,
    SearchLocationAutocompleteModule,
  ],
  providers: [
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: AUTH_SETTINGS,
      useValue: { appVerificationDisabledForTesting: true },
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "886353294166-vhdiofdmkhihi3t8nohpglfdtu63jcb5.apps.googleusercontent.com"
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebook.client_id),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
