import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Page } from "src/app/http-handler/common/contracts/page";
import { EventCategory } from "src/app/models/event-category.model";
import { PROFILE_TYPE, User, USER_ROLES } from "src/app/models/user.model";
import { ValidatorsService } from "src/app/modules/ui-modules/cv-form-field/services/validation.service";
import { GoogleSearchAddress } from "src/app/modules/ui-modules/search-location-autocomplete/google-search-location.constants";
import { AppService } from "src/app/services/app.service";
import { EventCategoryService } from "src/app/services/event-category.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
})
export class UserInfoComponent implements OnInit {
  isLoading: boolean = false;
  id?: string = "";
  today = new Date();
  userRole = "Event Manager";

  categoryEvents: Page<EventCategory> = new Page({
    api: this.eventCategoryService.categories,
    properties: new EventCategory(),
    serverPaging: false,
    filters: [
      { field: "isSelected", value: true },
      { field: "isEnabled", value: true },
    ],
  });

  formGroup: FormGroup = new FormGroup(
    {
      dateOfBirth: new FormControl("", [ValidatorsService.required]),
      eventManagerId: new FormControl("", []),
      eventCategoryIds: new FormControl("", [Validators.required]),
      location: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
      role: new FormControl(""),
      profileTypes: new FormControl(),
      locationCoordinates: new FormGroup({
        lat: new FormControl(NaN, [Validators.required]),
        lng: new FormControl(NaN, [Validators.required]),
      }),
      nickName: new FormControl("", [
        Validators.required,
        ValidatorsService.spaceValidator,
      ]),
      showLocation: new FormControl(true, []),
    },
    [Validators.required]
  );

  get formGroupValues(): User {
    return this.formGroup.value;
  }

  constructor(
    private _userService: UserService,
    public appService: AppService,
    private _activatedRoute: ActivatedRoute,
    public eventCategoryService: EventCategoryService,
    @Inject(MAT_DIALOG_DATA)
    public data: { profileTypes: PROFILE_TYPE[] },
    private dialogRef: MatDialogRef<UserInfoComponent>
  ) {
    dialogRef.addPanelClass("backdrop-black");
    this.categoryList();
    if (this.appService.isDetailCompleted) {
      this.dialogRef.close(true);
      this.appService.router.navigate(["/"]);
    }
    this.id = this.appService.user?.id;
    ValidatorsService.updateForm(this.formGroup, this.appService.user);
    this.formGroup.get("profileTypes")?.setValue(this.data.profileTypes);
    if (appService.user?.eventCategories?.length) {
      let eventCategories = appService.user?.eventCategories.map((d) => {
        return d.eventCategory.id;
      });
      this.formGroup.get("eventCategoryIds")?.setValue(eventCategories);
    }
    if (appService.isEventManager) {
      this.userRole = "AR & O";
      this.formGroup.get("eventManagerId")?.disable();
    }
    if (appService.isUser) {
      this.userRole = "Music Lover";
      this.formGroup.get("eventManagerId")?.disable();
      this.formGroup.get("eventCategoryIds")?.disable();
      this.formGroup.get("dateOfBirth")?.disable();
    }
  }

  ngOnInit() {}
  onLocationChange(data: GoogleSearchAddress) {
    this.formGroup.get("location")?.setValue(data.address);
    ValidatorsService.updateForm(
      this.formGroup.get("locationCoordinates")!,
      data.locationCoordinates
    );
    this.formGroup.get("country")?.setValue(data.location?.countryCode);
  }
  async categoryList() {
    try {
      await this.categoryEvents.fetch();
    } catch (error) {
      this.appService.openToster(error);
    }
  }
  clearLocation() {
    this.formGroup.get("locationCoordinates")?.reset();
    this.formGroup.get("country")?.reset();
    this.formGroup.get("location")?.setErrors({ invalid: true });
  }

  goBack() {
    if (localStorage.getItem("oldUrl")) {
      if (localStorage.getItem("oldUrl")?.includes("manager")) {
        this.appService.user = {
          ...this.appService.user,
          currentProfile: {
            profileType: PROFILE_TYPE.EVENTMANAGER,
          },
        } as any;
      }
      if (localStorage.getItem("oldUrl")?.includes("user")) {
        this.appService.user = {
          ...this.appService.user,
          currentProfile: {
            profileType: PROFILE_TYPE.LIVINGROOM,
          },
        } as any;
      }
      this.appService.router.navigate([localStorage.getItem("oldUrl")]);
      localStorage.removeItem("oldUrl");
      this.dialogRef.close(true);
      return;
    }
    this.appService.user = {
      ...this.appService.user,
    } as any;
    this.appService.router.navigate(["/"]);
    this.dialogRef.close(true);
  }

  async update() {
    if (this.isLoading) return;
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    try {
      this.isLoading = true;
      await this._userService.users.update(this.id, this.formGroup.value);
      const user = await this._userService.updateLocalUser();
      this.isLoading = false;
      const eventUrl = localStorage.getItem("eventUrl");
      if (
        user.currentProfile?.profileType == PROFILE_TYPE.EVENTMANAGER &&
        !eventUrl
      ) {
        this.appService.router.navigate(["manager"]);
      } else if (
        user.currentProfile?.profileType == PROFILE_TYPE.LIVINGROOM &&
        !eventUrl
      ) {
        this.appService.router.navigate(["user"]);
      } else {
        if (
          user.currentProfile?.profileType == PROFILE_TYPE.LIVINGROOM &&
          eventUrl
        ) {
          this.appService.router.navigate([`user/home/full/${eventUrl}`]);
        } else {
          this.appService.router.navigate(["/manager"]);
        }
      }
      this.dialogRef.close(true);
    } catch (error: any) {
      this.appService.openToster(error);
      this.isLoading = false;
    }
  }

  localization = {
    FirstName: "ValidationService.FirstName",
    LastName: "ValidationService.LastName",
    Genre: "ValidationService.Genre",
    Age: "ValidationService.Age",
    LivingRoomName: "ValidationService.LivingRoomName",
    Location: "ValidationService.Location",
    backTxt: "UserInfoComponent.backTxt",
    addDetailsTxt: "UserInfoComponent.addDetailsTxt",
    description: "UserInfoComponent.description",
    asTxt: "UserInfoComponent.asTxt",
    inputOrgName: "UserInfoComponent.inputOrgName",
    inputGenre: "UserInfoComponent.inputGenre",
    inputAge: "UserInfoComponent.inputAge",
    inputLivingRoom: "UserInfoComponent.inputLivingRoom",
    inputLocation: "UserInfoComponent.inputLocation",
    continueTxt: "UserInfoComponent.continueTxt",
    inputFirstName: "UserInfoComponent.inputFirstName",
    inputLastName: "UserInfoComponent.inputLastName",
  };
}
