import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[cv-append]'
})
export class FieldAppendDirective {

  constructor(
    private _el:ElementRef<HTMLElement>
  ) { }

  get element(){
    return this._el.nativeElement;
  }

}
