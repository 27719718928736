import { Injectable } from '@angular/core';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Injectable({
  providedIn: 'root'
})

export class SocialLoginService {

  constructor(
    private authService: SocialAuthService,
  ) { }

  async FBLogin() {
    try {
      const user = await this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
      return {
        facebookId: user?.id,
        firstName: user.firstName,
        lastName: user.lastName,
        secondaryEmail: user?.email,
        imgUrl: user?.photoUrl,
        loginType: 'facebook'
      }
    } catch (error) {
      return null;
    }
  }

  async googleLogin() {
    try {
      const user = await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
      return {
        googleId: user?.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user?.email,
        imgUrl: user?.photoUrl,
        loginType: 'google'
      }
    } catch (error) {
      return null;
    }
  }

  async logout() {
    const u = await this.authService.authState.toPromise();
    if (u) {
      this.authService.signOut();
    }
    return true;
  }

}
