import {
  AfterViewInit,
  Directive,
  EventEmitter,
  NgZone,
  Output,
} from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { ElementRef } from "@angular/core";
import { GoogleSearchAddress } from "../../google-search-location.constants";
declare var google: any;

@Directive({
  selector: "[googleSearchLocation]",
})
export class GoogleSearchLocationDirective implements AfterViewInit {
  geocoder: any;
  @Output() onLocationChange: EventEmitter<GoogleSearchAddress> =
    new EventEmitter<GoogleSearchAddress>();

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {}

  ngAfterViewInit() {
    this.gooleSetUp();
  }

  gooleSetUp() {
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        this.el.nativeElement
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(async () => {
          let place: any = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          const address = place.formatted_address;
          const location = this.getFullAddress(place);
          const locationCoordinates = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          this.onLocationChange.emit({
            address,
            location,
            locationCoordinates,
          });
        });
      });
    });
  }

  getFullAddress(place: any) {
    let location = {
      street: this.findAddress(place.address_components, "sublocality"),
      city: this.findAddress(place.address_components, "locality"),
      countryCode: this.findAddress(
        place.address_components,
        "country",
        "short_name"
      ),
      country: this.findAddress(place.address_components, "country"),
      zipcode: this.findAddress(place.address_components, "postal_code"),
      state: this.findAddress(
        place.address_components,
        "administrative_area_level_1"
      ),
    };
    return location;
  }

  findAddress(address: any[], key: string, id?: any) {
    if (!address.length) return "";
    let ad;
    if (key === "sublocality") {
      ad = address
        .filter(
          (i) =>
            i.types.includes("street_number") ||
            i.types.includes("route") ||
            i.types.includes("neighborhood") ||
            i.types.includes("administrative_area_level_3") ||
            i.types.includes("sublocality")
        )
        .map((i) => i.long_name)
        .join(", ");
      return ad;
    } else {
      ad = address.find((i) => i.types.includes(key));
      if (ad && (ad[id] || ad.long_name)) return ad[id] || ad.long_name;
    }
  }
}
