import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "currencyConverter" })

export class CurrencyConverter implements PipeTransform {

  constructor() { }

  transform(value: any) {
    return value;
  }
}
