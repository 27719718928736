<button cvTransparentButton class="back" (click)="goBack()">
  <img class="mr-2" src="assets/images/ic_back.svg" alt="" />
  {{ localization.backTxt | translate }}
</button>

<div class="container h-100">
  <div class="content h-100">
    <cv-card class="w-100 h-100 pb-0">
      <h3 class="page-title">Tell us, who you are</h3>

      <div class="c-lightwhite mb-4 text-center font-15">
        Multiple selections possible.
        <br />
        Can be changed later in the profile settings
      </div>

      <div class="content mpb-5">
        <div class="user-type-card" (click)="selectAndSubmit(PROFILE_TYPE.LIVINGROOM)" [class.selected]="isLivingRoom">
          <div class="icon">
            <img src="assets/images/living-room-type.svg" />
          </div>
          <div class="body">
            <p class="title clck-able">Music Lover</p>
            <p class="sub-title">
              {{
              localization.livingRoomSubTitle
              | translate
              }}
            </p>
          </div>
        </div>

        <div class="user-type-card m-mt3" (click)="
                                    selectAndSubmit(PROFILE_TYPE.EVENTMANAGER)
                              " [class.selected]="isManager">
          <div class="icon">
            <img src="assets/images/event-manager-type.svg" />
          </div>
          <div class="body">
            <p class="title">AR & O</p>
            <p class="sub-title">
              {{
              localization.eventOrgTxt
              | translate
              }}
            </p>
          </div>
        </div>
      </div>
      <footer class="text-center my-4">
        <button orangeBtn (click)="userInfo()" [disabled]="!this.type.length">
          Submit
        </button>
        <div class="step-text">Step 3 of 4</div>
      </footer>
    </cv-card>
  </div>
</div>