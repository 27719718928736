import { Component, OnInit } from "@angular/core";

@Component({
  selector: "cv-card",
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: inline-block;
        border-radius: 10px;
        opacity: 1;
        backdrop-filter: blur(32px);
        background: #ffffff2b;
        padding: 1.5rem;
      }
    `,
  ],
})
export class CvCardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
