import { Component, OnInit } from "@angular/core";
import { LocalizationService } from "./services/localization.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  isLoading: boolean = true;
  constructor(public _localizationService: LocalizationService) {
    localStorage.setItem("localize", localStorage.getItem("localize") || "en");
    this.loadStrings();
  }

  ngOnInit(): void {}

  async loadStrings() {
    this._localizationService.isLoading = true;
    await this._localizationService.fetchAppConfig();
    this._localizationService.isLoading = false;
  }
}
