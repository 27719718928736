import { UserInfoComponent } from "./../modules/auth/auth-pages/user-info/user-info.component";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IApi } from "../http-handler/common/contracts/api";
import { GenericApi } from "../http-handler/common/generic-api";
import { EventArtistInvites } from "../models/event.model";
import {
  User,
  UserAgoraData,
  USER_ROLES,
  PROFILE_TYPE,
} from "../models/user.model";
import { AUTH_NAVIGATIONS } from "../modules/auth/auth-constants";
import { AppService } from "./app.service";
import { FirebaseMessagingService } from "./firebase-messaging.service";
import { SocialLoginService } from "./social-login.service";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";
import { UserTypeSelectionComponent } from "../modules/auth/auth-pages/user-type-selection/user-type-selection.component";
import { MatDialog } from "@angular/material/dialog";

export class Artist {
  id: string;
  nickName: string;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  id: any;
  users: IApi<User>;
  artistList: IApi<Artist>;
  userDialogOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _firebaseDevice = {
    deviceId: this._firebaseMessagingService.deviceId,
    deviceType: "web",
  };

  constructor(
    private _http: HttpClient,
    private _appService: AppService,
    private _socialLoginService: SocialLoginService,
    private _firebaseMessagingService: FirebaseMessagingService,
    private dialog: MatDialog
  ) {
    this.users = new GenericApi<User>("users", this._http);
    this.artistList = new GenericApi<Artist>("users/artist/list", this._http);
  }

  displayName(u: User) {
    return u.nickName || `${u.firstName || ""} ${u.lastName || ""}`;
  }

  async login(data: any, type?: string) {
    try {
      this._firebaseDevice.deviceId = this._firebaseMessagingService.deviceId;
      const res = await this.users.create(
        { ...data, ...this._firebaseDevice },
        "signin"
      );
      if (type == "admin") {
        if (res?.role?.name == "user") {
          this._appService.openToster(
            `${this.localization.Pleaseentervalidcredentials}`
          );
          return;
        }
      }
      if (res.token) {
        AppService.currencySymbol = res?.currencySymbol;
        this._appService.token = res.token;
        const data = await this.updateLocalUser(res?.id);
        console.log("data is :", data);
      }
      if (!this._appService!.user!.timezone) {
        this._appService!.user!.timezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
        await this.updateTimeZone();
      }
      const eventUrl = localStorage.getItem("eventUrl");
      if (
        res?.currentProfile?.profileType == PROFILE_TYPE.EVENTMANAGER &&
        !eventUrl
      ) {
        this._appService.router.navigate(["/manager"]);
      } else if (
        res?.currentProfile?.profileType == PROFILE_TYPE.LIVINGROOM &&
        !eventUrl
      ) {
        this._appService.router.navigate(["/user"]);
      } else if (
        !res?.currentProfile &&
        this._appService.token &&
        this._appService.user?.role?.name != USER_ROLES.ADMIN
      ) {
        this.userTypeSelectionDialog();
      } else {
        if (
          res?.currentProfile?.profileType == PROFILE_TYPE.LIVINGROOM &&
          eventUrl
        ) {
          this._appService.router.navigate([`user/home/full/${eventUrl}`]);
        } else {
          this._appService.router.navigate(["/manager"]);
        }
      }
      return res;
    } catch (err) {
      this._appService.openToster(err);
      return false;
    }
  }

  userTypeSelectionDialog() {
    this.dialog.open(UserTypeSelectionComponent, {
      panelClass: "noPadding",
      disableClose: true,
      autoFocus: false,
      maxHeight: "96vh",
      maxWidth: "100vh",
    });
  }

  UserInfoComponent() {
    this.dialog.open(UserInfoComponent, {
      data: {
        profileTypes: [this._appService.user?.currentProfile.profileType],
      },
      panelClass: "noPadding",
      disableClose: true,
      autoFocus: false,
    });
  }

  async updateTimeZone() {
    this.id = this._appService.user?.id;
    try {
      let d = await this.users.update(this.id, {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      } as any);
      this._appService.user = { ...this._appService.user, ...d };
      return this._appService.user;
    } catch (err: any) {
      this._appService.openToster(err);
      return null;
    }
  }

  async updateUser() {
    this.id = this._appService.user?.id;
    try {
      let d = await this.users.update(this.id, {
        appLanguage: localStorage.getItem("localize"),
      } as any);
      this._appService.user = { ...this._appService.user, ...d };

      return this._appService.user;
    } catch (err: any) {
      this._appService.openToster(err);
      return null;
    }
  }

  isLoading: boolean = false;
  async logOut() {
    try {
      this.isLoading = true;
      this._socialLoginService.logout();
      await this.users.update(
        this._appService.user?.id as any,
        {} as any,
        undefined,
        "logout"
      );
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    } finally {
      this._appService.clearLocalStorage();
      this._appService.router.navigate([
        `${
          environment.panel == "admin-dev" ? AUTH_NAVIGATIONS.LOGIN : "public"
        }`,
      ]);
      this.isLoading = false;
    }
  }

  async signUpWithFacebook() {
    try {
      const data = await this._socialLoginService.FBLogin();
      if (!data?.facebookId) {
        return false;
      }
      const fbUser = await this.checkSocialLogin(data.facebookId, "facebook");
      const user = await this.signUp({ ...fbUser, ...data } as any);
      if (!user) {
        return false;
      }
      if (user.currentProfile) {
        this._appService.router.navigate(["/"]);
      } else {
        this.userTypeSelectionDialog();
      }
      return true;
    } catch (error) {
      this._appService.openToster(error);
      return false;
    }
  }

  async signUpWithGoogle() {
    try {
      const data = await this._socialLoginService.googleLogin();
      if (!data?.googleId) {
        return false;
      }
      const gUser = await this.checkSocialLogin(data.googleId, "google");
      const user = await this.signUp({ ...gUser, ...data } as any);
      if (!user) {
        return false;
      }
      if (user.currentProfile) {
        this._appService.router.navigate(["/"]);
      } else {
        this.userTypeSelectionDialog();
      }
      return true;
    } catch (error) {
      this._appService.openToster(error);
      return false;
    }
  }

  async signUp(data: User) {
    try {
      this._firebaseDevice.deviceId = this._firebaseMessagingService.deviceId;
      const res = await this.users.create(
        { ...data, ...this._firebaseDevice } as any,
        "signup"
      );
      if (res.token) {
        this._appService.token = res.token;
        await this.updateLocalUser(res?.id);
      }
      return this._appService.user || res;
    } catch (err: any) {
      this._appService.openToster(err);
      return null;
    }
  }

  async checkValidEmail(email: string, checkWithSecondaryEmail = false) {
    try {
      const res: false | User = await this.users.simpleGet({
        path: `check/email?email=${email}&checkWithSecondaryEmail=${checkWithSecondaryEmail}`,
      });
      return res;
    } catch (err) {
      return false;
    }
  }

  async checkSocialLogin(id: string, type: "facebook" | "google") {
    try {
      const res: User = await this.users.simpleGet({
        path: `get/${type}Id?${type}Id=${id}`,
      });
      return res;
    } catch (err) {
      return false;
    }
  }

  async verify(data: any, isStoreUser = false) {
    try {
      this._firebaseDevice.deviceId = this._firebaseMessagingService.deviceId;
      const res = await this.users.create(
        { ...data, ...this._firebaseDevice } as any,
        "verify"
      );
      if (isStoreUser) {
        this._appService.user = res;
        if (res.token) this._appService.token = res.token;
      }
      return res;
    } catch (err) {
      this._appService.openToster(`${this.localization.InvalidOTP}`);
      return null;
    }
  }

  async resendOtp(userId: any) {
    try {
      const res = await this.users.create({ userId } as any, "resend");
      this._appService.openToster(`${this.localization.otpResent}`);
      return res;
    } catch (err: any) {
      this._appService.openToster(err);
      return null;
    }
  }

  async forgotPassword(data: any) {
    try {
      const res = await this.users.create(data as any, "forgotPassword");
      this._appService.openToster(`${this.localization.otpSent}`);
      return res;
    } catch (err: any) {
      this._appService.openToster(err);
      return null;
    }
  }

  async updatePassword(userId: string, data: any) {
    try {
      const res = await this.users.update(
        userId,
        data as any,
        undefined,
        `updatePassword/${userId}`
      );
      if (this._appService.isAdmin) {
        this._appService.openToster(`${this.localization.otpSent}`);
      } else {
        this._appService.openToster(`${this.localization.yourPwd}`);
      }
      return res;
    } catch (err: any) {
      this._appService.openToster(err);
      return null;
    }
  }

  async updateLocalUser(userId?: any): Promise<User> {
    this.id = this._appService.user?.id || userId;
    try {
      let d = await this.users.get(this.id);
      d.appLanguage = d.appLanguage || "en";
      this._appService.user = { ...d };

      return this._appService.user;
    } catch (err: any) {
      this._appService.openToster(err);
      return err;
    }
  }

  async userSendInvite(data: EventArtistInvites) {
    `${this.localization.inviteSent}`;
    try {
      await this.users.create(data as any, `send/invite`);
      this._appService.openToster(`${this.localization.inviteSent}`);
      return true;
    } catch (err: any) {
      this._appService.openToster(err);
      return null;
    }
  }

  async switchRole(role: PROFILE_TYPE) {
    const user = this._appService.user;
    user!.currentProfile.profileType = role;
    this._appService.user = user;
    const profile = this._appService.user?.profiles?.find((d) => {
      return role == d.profileType;
    });
    let body: any = {};
    body.profileId = profile?.id;
    body.profileType = role;
    if (this._appService.isDetailCompleted) {
      await this.users.update(
        user!.id,
        body as any,
        undefined,
        `switch/${user!.id}/profile`
      );
    } else {
      localStorage.setItem("oldUrl", this._appService.router.url);
    }
    location.href = "/";
  }

  async createAgoraToken(
    eventArtistChannelId: string | undefined,
    eventId: string,
    eventArtistId: string | undefined
  ) {
    try {
      const res: UserAgoraData = await this.users.create(
        {
          userId: this._appService?.user?.id || null,
          eventArtistChannelId,
          eventId,
          eventArtistId,
        } as any,
        "new/create/tokens"
      );
      return res;
    } catch (error: any) {
      this._appService.openToster(error);
      throw error;
    }
  }

  async createAgoraTokenDrinkRoom(
    drinkRoomId: string | undefined,
    eventId: string,
    eventArtistId: string | undefined
  ) {
    try {
      const res: UserAgoraData = await this.users.create(
        {
          userId: this._appService?.user?.id || null,
          drinkRoomId,
          eventId,
          eventArtistId,
        } as any,
        "new/create/tokens"
      );
      return res;
    } catch (error: any) {
      this._appService.openToster(error);
      throw error;
    }
  }

  async inviteDrink(toUserId: string, eventId: string) {
    try {
      const res = await this.users.create(
        {
          toUserId,
          eventId,
          fromUserId: this._appService.user!.id,
        } as any,
        "drink/request"
      );
      this._appService.openToster(`${this.localization.requestSent}`);
      return res;
    } catch (error: any) {
      this._appService.openToster(error);
      return null;
    }
  }

  async danceOnCount(toUserId: string) {
    try {
      const res = await this.users.create(
        {
          toUserId,
          fromUserId: this._appService.user!.id,
        } as any,
        "drink/request"
      );
      return res;
    } catch (error: any) {
      this._appService.openToster(error);
      return null;
    }
  }

  async pinUser(toUserId: string, eventId: string) {
    try {
      const res = await this.users.create(
        {
          toUserId,
          fromUserId: this._appService.user!.id,
          eventId,
        } as any,
        "pin/user"
      );
      return true;
    } catch (error: any) {
      this._appService.openToster(error);
      return null;
    }
  }

  localization = {
    otpResent: "UserService.otpResent",
    InvalidOTP: "UserService.InvalidOTP",
    otpSent: "UserService.otpSent",
    userPwdUpdated: "UserService.userPwdUpdated",
    inviteSent: "UserService.inviteSent",
    yourPwd: "UserService.yourPwd",
    requestSent: "SuccessErrorMessages.Requestsentsuccessfully",
    Pleaseentervalidcredentials:
      "SuccessErrorMessages.Pleaseentervalidcredentials",
  };
}
