import { Component, HostBinding, OnInit } from "@angular/core";
import { InternetConnectionService } from "src/app/services/internet-connection.service";

@Component({
  selector: "connection-status",
  template: `
    {{
      isConnected
        ? "Back to connected."
        : "Connection lost. Please check your internet connection."
    }}
  `,
  styles: [
    `
      :host {
        position: fixed;
        bottom: -45px;
        left: 0;
        width: 100%;
        z-index: 1;
        padding: 10px;
        color: white;
        font: normal normal 600 15px/24px KoHo;
        transition: bottom 0.25s ease-in-out;
        transition-delay: 2s;
        text-align: center;
        &.isConnected {
          background: green;
        }
        &.disConnected {
          background: red;
          bottom: 0px;
        }
      }
    `,
  ],
})
export class ConnectionStatusComponent implements OnInit {
  constructor(public connectionService: InternetConnectionService) {}

  get isConnected() {
    return this.connectionService.status == "ONLINE";
  }

  ngOnInit() {}

  @HostBinding("class.isConnected") get connected() {
    return this.isConnected;
  }
  @HostBinding("class.disConnected") get disConnected() {
    return !this.isConnected;
  }

  localization = {
    backToConnected: "ConnectionStatusComponent.backToConnected",
    connectionLost: "ConnectionStatusComponent.connectionLost",
  };
}
