import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CurrencyConverter } from "./pipes/currency-conversion.pipe";
import { NgxSkeletonModule } from "ngx-skeleton";
import { TimePickerComponent } from "./time-picker/time-picker.component";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslatePipe } from "../admin-console/admin-main/pipe/translate.pipe";

const EXPORT_MODULES = [
  FormsModule,
  ReactiveFormsModule,
];

const MODULES = [CommonModule, ...EXPORT_MODULES];

@NgModule({
  imports: [MODULES, NgxSkeletonModule, MatSelectModule, MatTooltipModule],
  declarations: [CurrencyConverter, TimePickerComponent, TranslatePipe],
  exports: [EXPORT_MODULES, CurrencyConverter, NgxSkeletonModule, TimePickerComponent, TranslatePipe],
})
export class CommonSharedModule { }


