import { EventCategory } from "./event-category.model";
import { Friends } from "./friends.model";
import { Subscription } from "./subscription.model";

export class UserAgoraData {
  userAgoraToken?: string;
  userAgoraUniquId?: string;
  rtmpUrl: string;
}

export class User extends UserAgoraData {
  id: string = "";
  uniqueId: string = "";
  firstName: string = "";
  lastName: string = "";
  lastLogin: string = "";
  isUserVisited: boolean = false;
  accountStatus?: string;
  aboutMe?: string;
  dateOfBirth?: string;
  website?: string;
  socialLinks?: SocialLinks;
  isFollow: boolean;
  eventCategories: EventCategories[];
  isArtistFavourite: boolean;
  fullName: string = "";
  createdAt: string = "";
  email: string = "";
  adminCommissionPercent?: any;
  deviceType: "web" = "web";
  token: string = "";
  role?: Role;
  isFreeEvent: boolean;
  languages: string[] = [];
  secondaryEmail: string = "";
  isEmailVerified: boolean = false;
  facebookId: string = "";
  googleId: string = "";
  imgUrl: string = "";
  isFavourite: boolean = false;
  eventsPerformed: number = 0;
  currencySymbol?: any;
  overallRating: number = 0;
  name: string = "";
  currency: string = "";
  surName: string = "";
  invitesSent: string = "";
  age: number = NaN;
  status: USER_STATUS = USER_STATUS.ACTIVE;
  location: string = "";
  locationCoordinates: Coordinates = new Coordinates();
  clubName: string = "";
  description: string = "";
  eventsOrganised: number = 0;
  eventsAttended: number = 0;
  isLive: boolean = false;
  isAccountVerified: boolean = false;
  appLanguage: string = "";
  timezone: string = "";
  isNotifications: boolean = false;
  fbLink: string = "";
  twitterLink: string = "";
  linkedLink: string = "";
  instagramLink: string = "";
  companyName: string = "";
  country: string = "";
  address: string = "";
  loginType?: LOGIN_TYPE;
  agoraUniqueIds?: number[] = [];
  isBlocked: boolean = false;
  showLocation?: boolean;
  totalEventAmountReceived: number = 0;
  totalDonationAmountSpent: number = 0;
  totalDonationAmountReceived: number = 0;
  totalDonationAmountReceivedAsArtist: number = 0;
  totalEventAmountReceivedAsArtist: number = 0;
  friendsCount?: number;
  eventCategory?: EventCategory = new EventCategory();
  eventCategoryId?: string;
  isArtistProfileComplete?: boolean;
  isEventManagerProfileComplete?: boolean;
  isLivingRoomProfileComplete?: boolean;
  genre?: string;
  customerData?: any;
  friend?: Friends;
  friends?: Friends[];
  invoiceAdress?: InvoiceAddress;
  isPasswordUpdate?: boolean;
  accessRole?: string;
  eventManagerId?: string;
  nickName: string;
  profileTypes: [];
  profiles?: Profile[];
  currentProfile: Profile;
  isArtistOrDj?: boolean = false;
  userSubscription?: {
    totalMinutes?: number;
    subscriptionType: string;
    endDate?: string;
    subscription: Subscription;
  };
}

export class Profile {
  id?: string;
  profileType: PROFILE_TYPE;
  website?: string;
  description?: string;
  aboutMe?: string;
}

export class Role {
  id: string;
  name: USER_ROLES;
}

export class InvoiceAddress {
  city?: string;
  country?: string;
  postalCode?: number;
  street?: string;
  taxNo?: string;
}

export class FirebaseUser {
  isOnline!: boolean;
  isNewMessages!: boolean;
  isNewGroupMessages!: boolean;
}

export class Coordinates {
  lat: number = NaN;
  lng: number = NaN;
}

export enum LOGIN_TYPE {
  EMAIL = "email",
  FACEBOOK = "facebook",
  GOOGLE = "google",
}

export enum USER_STATUS {
  ACTIVE = "active",
}

export enum USER_ROLES {
  USER = "user",
  ARTIST = "artist",
  MANAGER = "eventManager",
  ADMIN = "admin",
  Substitute = "substitute",
}

export enum PROFILE_TYPE {
  LIVINGROOM = "livingRoom",
  EVENTMANAGER = "eventManager",
  SUBSTITUTE = "substitute",
}
export class EventCategories {
  eventCategory: EventCategory;
}

export class SocialLinks {
  soundCloudProfile: string;
  spotifyProfile: string;
  youtubeProfile: string;
  instaProfile: string;
  residentAdvisorProfile: string;
  tiktokProfile: string;
  fbProfile: string;
}
