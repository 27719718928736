import { Injectable } from '@angular/core';
import {
      CanActivate,
      ActivatedRouteSnapshot,
      RouterStateSnapshot,
      UrlTree,
      Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PROFILE_TYPE } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';

@Injectable({
      providedIn: 'root',
})
export class ManagerConsoleGuard implements CanActivate {
    constructor(
        private router: Router,
        private _appService: AppService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this._appService.currentProfileType === PROFILE_TYPE.EVENTMANAGER && this._appService.isDetailCompleted || this._appService.currentProfileType === PROFILE_TYPE.SUBSTITUTE) {
            return true
        }
        this.router.navigate(['user'])
        return false;
    }
}
