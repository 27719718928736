import "hammerjs";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import Gleap from 'gleap';

if (environment.production) {
  enableProdMode();
}

// Please make sure to call this method only once!
// Gleap.initialize("PmcDoOiYHlYsUpO8EmNPb85QqnjlpHkq");

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
