import {
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  CV_FORM_FIELD_OPTIONS,
  FormFieldOptions,
} from "./cv-form-field.constants";
import { FieldAppendDirective } from "./field-append/field-append.directive";
import { FieldPrefixDirective } from "./field-prefix/field-prefix.directive";
import { InputFieldDirective } from "./input-field/input-field.directive";
import { AppService } from "src/app/services/app.service";
import { USER_ROLES } from "src/app/models/user.model";

@Component({
  selector: "cv-form-field",
  templateUrl: "./cv-form-field.component.html",
  styleUrls: ["./cv-form-field.component.scss"],
})
export class CvFormFieldComponent implements OnInit {
  @ViewChild("prefix") private prefixContainer?: ElementRef<HTMLDivElement>;
  @ViewChild("innerFix") private innerContainer?: ElementRef<HTMLDivElement>;
  @ViewChild("appendAfter")
  private appendAfterContainer?: ElementRef<HTMLDivElement>;
  @ViewChild("label") private label?: ElementRef<HTMLLabelElement>;
  @Input() shadow: boolean = false;
  @Input() mode: string = "";
  @ContentChild(InputFieldDirective) input?: InputFieldDirective;
  @ContentChild(FieldPrefixDirective)
  private prefixContent?: FieldPrefixDirective;
  @ContentChild(FieldAppendDirective)
  private appendAfterContent?: FieldAppendDirective;

  constructor(
    private _renderer2: Renderer2,
    private _el: ElementRef<HTMLElement>,
    private appService: AppService,
    @Inject(CV_FORM_FIELD_OPTIONS) options?: FormFieldOptions
  ) {
    if (options?.isShadow) {
      this.shadow = options?.isShadow;
    }
  }

  ngOnInit() { }

  ngAfterViewInit() {
    if (this.prefixContainer && this.prefixContent) {
      this._renderer2.appendChild(
        this?.prefixContainer?.nativeElement,
        this.prefixContent?.element
      );
    }
    if (this.innerContainer && this.input && this.label) {
      this._renderer2.insertBefore(
        this?.innerContainer?.nativeElement,
        this.input?.element,
        this.label.nativeElement
      );
    }
    if (this.appendAfterContainer && this.appendAfterContent) {
      this._renderer2.appendChild(
        this?.appendAfterContainer?.nativeElement,
        this.appendAfterContent?.element
      );
    }
  }

  ngAfterContentInit() { }

  get isPassword() {
    return this.input?.element?.type === "password";
  }

  viewPasswordToggle() {
    if (this.input?.element instanceof HTMLTextAreaElement) return;
    if (this.input?.element?.type === "password") {
      this.input.element.type = "text";
    } else if (this.input?.element?.type === "text") {
      this.input.element.type = "password";
    }
  }

  @HostBinding("class.shadow") get isShadow() {
    return this.shadow;
  }

  @HostBinding("class.admin") get isAdmin() {
    return this.appService.user?.role?.name == USER_ROLES.ADMIN;
  }

  @HostListener("click", ["$event"]) private click() {
    if (this.input?.element?.focus) {
      this.input?.element?.focus();
    }
  }
}
