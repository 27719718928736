import { Directive, ElementRef, HostBinding, Renderer2 } from '@angular/core';

@Directive({
  selector: '[cv-prefix], cv-prefix'
})
export class FieldPrefixDirective {

  constructor(
    private _renderer2: Renderer2,
    private _el: ElementRef<HTMLElement>
  ) {
  }

  get element(){
    return this._el.nativeElement;
  }

  @HostBinding('class.prefix') readonly mainClass?: boolean = true;


}

