import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isEmpty } from "lodash";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { environment } from "src/environments/environment";

enum LANG {
  EN = "en",
  GE = "ge",
}

const LANGS = [LANG.EN, LANG.GE];
@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  data = new BehaviorSubject({});
  static jsonData: any = {};
  isLoading: boolean = true;

  constructor(private http: HttpClient) {
    this.data.subscribe((d) => (LocalizationService.jsonData = d));
  }

  async load() {
    this.isLoading = true;
    await this.http
      .get("../../assets/i18n/en.json")
      .toPromise()
      .then((d) => {
        this.setData(d);
      });
    this.isLoading = false;
    return;
  }

  setData(d: any) {
    this.isLoading = true;
    this.data.next(d);
    this.isLoading = false;
  }

  async fetchAppConfig() {
    try {
      this.isLoading = true;
      let res: any = await this.http
        .get(
          `${environment.apiUrls.api}/api/languages/code/${
            localStorage.getItem("localize") || "en"
          }`
        )
        .toPromise();
      if (!isEmpty(res?.data?.webAppJSON)) {
        this.setData(res?.data?.webAppJSON);
        this.isLoading = false;
        return;
      }
      throw "backend JSON is empty";
    } catch (error) {
      console.log(error);
      return await this.load();
    } finally {
      this.isLoading = false;
    }
  }

  static getDataValue(value: string) {
    let valueData = LocalizationService.jsonData;
    value?.split(".")?.forEach((d: any) => {
      valueData = valueData![d];
    });
    return valueData;
  }
}
