import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
} from "@angular/forms";
import { Injectable } from "@angular/core";
import { CreditCardService } from "./credit-card.service";
import { LocalizationService } from "src/app/services/localization.service";
@Injectable({
  providedIn: "root",
})
export class ValidatorsService {
  static cardNumber: string;
  constructor() {}

  static updateForm(
    group: FormGroup | FormControl | FormArray | AbstractControl,
    values: any
  ) {
    if (group instanceof FormControl) {
      group.setValue(values);
    } else if (group instanceof FormGroup) {
      Object.keys(group.controls).forEach((k) => {
        const control = group.get(k);
        if (values && values[k] !== undefined) {
          ValidatorsService.updateForm(control!, values[k]);
        }
      });
    } else if (group instanceof FormArray) {
      group.controls.forEach((c, index) => {
        if (values[index] !== undefined) {
          ValidatorsService.updateForm(c, values[index]);
        }
      });
    }
  }

  static error(errors: any, fieldName: string = "Field") {
    if (!errors) return null;
    for (let propertyName in errors) {
      if (errors.hasOwnProperty(propertyName)) {
        return ValidatorsService.getValidatorErrorMessage(propertyName, {
          ...errors[propertyName],
          fieldName,
        });
      }
    }
    return null;
  }

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config: any = {
      required: `${LocalizationService.getDataValue(
        this.localization.Pleaseenter
      )} ${
        validatorValue.fieldName
          ? `${validatorValue.fieldName}.`.toLowerCase()
          : ""
      }`,
      invalid: `${LocalizationService.getDataValue(
        this.localization.Pleaseentervalid
      )} ${
        validatorValue.fieldName
          ? `${validatorValue.fieldName}`.toLowerCase()
          : ""
      }`,
      selectRequired: `${LocalizationService.getDataValue(
        this.localization.Pleaseselect
      )}  ${
        validatorValue.fieldName ? validatorValue.fieldName.toLowerCase() : ""
      } `,
      compare: `${LocalizationService.getDataValue(
        this.localization.Newpasswordandconfirmsame
      )}`,
      min: `${LocalizationService.getDataValue(
        this.localization.Minimumvaluerequired
      )} ${validatorValue.min ? validatorValue.min : ""}`,
      max: `${LocalizationService.getDataValue(
        this.localization.Maximumvalueshouldbe
      )} ${validatorValue.max ? validatorValue.max : ""}`,
      maxlength: `${LocalizationService.getDataValue(
        this.localization.Maximumwordlimitreached
      )}`,
      minlength: `${LocalizationService.getDataValue(
        this.localization.Minimum
      )} ${
        validatorValue.requiredLength ? validatorValue.requiredLength : ""
      } ${LocalizationService.getDataValue(
        this.localization.charactersarerequired
      )}`,
      emailAlreadyExsist: `${LocalizationService.getDataValue(
        this.localization.Emailaddressexsist
      )}`,
      invalidPercentage: `${LocalizationService.getDataValue(
        this.localization.Pleaseentervalidpercentage
      )}`,
      phonelength: `${LocalizationService.getDataValue(
        this.localization.Pleaseentervalidphonenumber
      )}`,
      invalidName: `${LocalizationService.getDataValue(
        this.localization.Enteravalidname
      )}`,
      invalidEmailAddress: `${LocalizationService.getDataValue(
        this.localization.Invalidemailaddress
      )}`,
      invalidPassword: `${LocalizationService.getDataValue(
        this.localization.invalidPassword
      )}`,
      invalidNumber: `${LocalizationService.getDataValue(
        this.localization.invalidNumber
      )}`,
      invalidPhoneNumber: `${LocalizationService.getDataValue(
        this.localization.invalidPhoneNumber
      )}`,
      invalidCardNumber: `${LocalizationService.getDataValue(
        this.localization.invalidCardNumber
      )}`,
      invalidCVVNumber: `${LocalizationService.getDataValue(
        this.localization.invalidCVVNumber
      )}`,
      invalidCardExpiry: `${LocalizationService.getDataValue(
        this.localization.invalidCardExpiry
      )}`,
      invalidUrl: `${LocalizationService.getDataValue(
        this.localization.invalidUrl
      )}`,
      invalidTaxId: `${LocalizationService.getDataValue(
        this.localization.invalidTaxId
      )}`,
      invalidFaxNumber: `${LocalizationService.getDataValue(
        this.localization.invalidFaxNumber
      )}`,
      invalidZip: `${LocalizationService.getDataValue(
        this.localization.invalidZip
      )}`,
      invalidInput: `${LocalizationService.getDataValue(
        this.localization.invalidInput
      )}`,
      timeOverLap: `${LocalizationService.getDataValue(
        this.localization.timeOverLap
      )}`,
      minAmount: `${LocalizationService.getDataValue(
        this.localization.Minimumvaluerequired
      )} ${validatorValue.minAmount}.00 ${validatorValue.currency}`,
      timeNotInEventRange: `${
        validatorValue.fieldName
          ? validatorValue.fieldName
          : `${LocalizationService.getDataValue(this.localization.Time)}`
      } ${LocalizationService.getDataValue(
        this.localization.shouldbeineventtimerange
      )}`,
      endTimeBeforeStartTime: `${
        validatorValue.fieldName
          ? validatorValue.fieldName
          : `${LocalizationService.getDataValue(this.localization.Endtime)}`
      } ${LocalizationService.getDataValue(
        this.localization.shouldbegreaterthanstarttime
      )}`,
      invalidGoal: `${LocalizationService.getDataValue(
        this.localization.Pleaseentervalidgoal
      )}`,
      invalidYouTube: `${LocalizationService.getDataValue(
        this.localization.invalidYouTube
      )}`,
      invalidAccountNumber: `${LocalizationService.getDataValue(
        this.localization.invalidAccountNumber
      )}`,
      invalidEmployeeId: `${LocalizationService.getDataValue(
        this.localization.invalidEmployeeId
      )}`,
      specialCharacter: `${LocalizationService.getDataValue(
        this.localization.specialCharacter
      )}`,
      price: `${LocalizationService.getDataValue(
        this.localization.Pleaseentervalid
      )} ${
        validatorValue.fieldName ? validatorValue.fieldName.toLowerCase() : ""
      }.`,
    };
    return config[validatorName];
  }

  static min(control: any) {
    if (control.value) {
      if (control.value.length >= 10) {
        return null;
      }
    }
    return { min: true };
  }

  static minAmount(minAmount: any, currency: any) {
    return function min(control: any) {
      if (control.value) {
        if (control.value >= minAmount) {
          return null;
        }
      }
      return {
        minAmount: {
          minAmount: minAmount,
          currency: currency,
        },
      };
    };
  }

  static required(control: AbstractControl) {
    if (!control.value) {
      return { required: true };
    }
    return null;
  }

  static selectRequired(control: AbstractControl) {
    if (!control.value) {
      return { selectRequired: true };
    }
    return null;
  }

  static compareValidator(control1: string, control2: string): ValidatorFn {
    return function matchPassword(c: AbstractControl) {
      if (
        c.get(control1)?.value &&
        c.get(control2)?.value &&
        c.get(control1)?.value !== c.get(control2)?.value
      ) {
        c.get(control2)?.setErrors({ compare: true });
        return { invalid: true };
      } else {
        return null;
      }
    };
  }

  static spaceValidator(control: any) {
    if (control.value) {
      let v = `${control.value}`;
      if (v.replace(/\s+/g, "")) {
        return null;
      } else {
        return { invalidInput: true };
      }
    }
    return null;
  }

  static numberFieldRequiredValidator(control: any) {
    if (control.value) {
      return null;
    } else {
      return { required: true };
    }
  }

  static employeeId(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (`${control.value}`.match(/^(\w+)?\d+(\w+)?$/)) {
        return null;
      } else {
        return { invalidEmployeeId: true };
      }
    }
    return null;
  }

  static price(control: any) {
    const isValid = `${control.value}`.match(/[1-9]+(\0?\\.[0-9][0-9]?)?/);
    if (isValid?.length) {
      return null;
    } else {
      return { price: true };
    }
  }

  static specailCharacter(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (`${control.value}`.match(/^[0-9a-zA-Z ]+$/)) {
        return null;
      } else {
        return { specialCharacter: true };
      }
    }
    return null;
  }

  static fullNameValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      let str = control.value.replace(/\s+/g, "");
      if (
        control.value.match(/^([a-z])+([a-zA-Z0-9 .,-@&#!\'\\$\\s]*)$/i) &&
        str.length > 0
      ) {
        return null;
      } else {
        return { invalidName: true };
      }
    }
    return null;
  }

  static nameValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      let str = control.value.replace(/\s+/g, "");

      if (
        control.value.match(/^([a-z])+([a-zA-Z0-9 .,-@&#!\'\\$\\s]*)$/i) &&
        str.length > 0
      ) {
        return null;
      } else {
        return { invalidName: true };
      }
    }
    return null;
  }

  static emailValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (
        control.value.match(
          /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|glass|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
        )
      ) {
        return null;
      } else {
        return { invalidEmailAddress: true };
      }
    }
    return null;
  }

  static numberValidator(control: any) {
    const value = `${control.value}`;
    if (value && value.match(/^[0-9]*$/)) {
      return null;
    } else {
      return { invalidNumber: true };
    }
  }

  static percentageValidator(control: any) {
    const value: string = `${control.value}`;
    if (value) {
      if (
        value.match(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/g)
      ) {
        return null;
      } else {
        return { invalidPercentage: true };
      }
    }
    return null;
  }

  static phoneNumberValidator(control: any) {
    if (control.value) {
      if (control.value.match(/^\d{10}$/)) {
        return null;
      } else if (
        control.value.match(
          /^(\+\d{1,3}[- ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        )
      ) {
        return null;
      } else if (
        control.value.match(
          /^(\+\d{1,3}[- ]?)?\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
        )
      ) {
        return null;
      } else {
        return { invalidPhoneNumber: true };
      }
    }
    return null;
  }

  static phoneNumberWithoutDialCodeValidator(control: any) {
    if (control.value) {
      var p = `${control.value}`.match(/\d+/);
      if (`${control.value}`.match(/^\d{10,10}$/)) {
        return null;
      } else if (p && p?.length < 7) {
        return { phonelength: true };
      } else {
        return { invalidPhoneNumber: true };
      }
    }
    return null;
  }

  static passwordValidator(control: any) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    const value = `${control.value}`;
    if (value) {
      if (value.match(/\s/g)) {
        return { invalidInput: true };
      }
      if (value.match("(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})")) {
        return null;
      } else {
        return { invalidPassword: true };
      }
    }
    return null;
  }

  static urlValidator(control: any) {
    if (control.value) {
      if (
        control.value.match(
          /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/g
        )
      ) {
        return null;
      } else {
        return { invalidUrl: true };
      }
    }
    return null;
  }

  static setCardNumber(cardNumber: string) {
    this.cardNumber = cardNumber;
  }

  static getCardNumber(): string {
    return this.cardNumber;
  }

  static creditCardNumberValidator(control: any) {
    if (control.value) {
      let creditService = new CreditCardService();
      if (creditService.validateCardNumber(control.value)) {
        ValidatorsService.setCardNumber(control.value);
        return null;
      } else {
        ValidatorsService.setCardNumber("");
        return { invalidCardNumber: true };
      }
    }
    return null;
  }

  static creditCardCVVNumberValidator(control: any) {
    if (control.value) {
      let creditService = new CreditCardService();
      if (creditService.validateCardNumber(ValidatorsService.getCardNumber())) {
        if (
          creditService.validateCardCVC(
            control.value,
            creditService.parseCardType(ValidatorsService.getCardNumber())
          )
        ) {
          return null;
        } else {
          return { invalidCVVNumber: true };
        }
      }
    }
    return null;
  }

  static creditCardExpiryDateValidator(control: any) {
    let creditService = new CreditCardService();
    if (control.value) {
      let partsOfStr = control.value.split("/");
      if (creditService.validateCardExpiry(partsOfStr[0], partsOfStr[1])) {
        return null;
      } else {
        return { invalidCardExpiry: true };
      }
    }
    return null;
  }

  static localization = {
    Pleaseenter: "ValidationService.Pleaseenter",
    Pleaseentervalid: "ValidationService.Pleaseentervalid",
    Pleaseselect: "ValidationService.Pleaseselect",
    Newpasswordandconfirmsame: "ValidationService.Newpasswordandconfirmsame",
    Minimumvaluerequired: "ValidationService.Minimumvaluerequired",
    Maximumvalueshouldbe: "ValidationService.Maximumvalueshouldbe",
    Maximumwordlimitreached: "ValidationService.Maximumwordlimitreached",
    Minimum: "ValidationService.Minimum",
    charactersarerequired: "ValidationService.charactersarerequired",
    Emailaddressexsist: "ValidationService.Emailaddressexsist",
    Pleaseentervalidpercentage: "ValidationService.Pleaseentervalidpercentage",
    Pleaseentervalidphonenumber:
      "ValidationService.Pleaseentervalidphonenumber",
    Enteravalidname: "ValidationService.Enteravalidname",
    Invalidemailaddress: "ValidationService.Invalidemailaddress",
    invalidPassword: "ValidationService.invalidPassword",
    invalidNumber: "ValidationService.invalidNumber",
    invalidPhoneNumber: "ValidationService.invalidPhoneNumber",
    invalidCardNumber: "ValidationService.invalidCardNumber",
    invalidCVVNumber: "ValidationService.invalidCVVNumber",
    invalidCardExpiry: "ValidationService.invalidCardExpiry",
    invalidUrl: "ValidationService.invalidUrl",
    invalidTaxId: "ValidationService.invalidTaxId",
    invalidFaxNumber: "ValidationService.invalidFaxNumber",
    invalidZip: "ValidationService.invalidZip",
    invalidInput: "ValidationService.invalidInput",
    timeOverLap: "ValidationService.timeOverLap",
    Time: "ValidationService.Time",
    shouldbeineventtimerange: "ValidationService.shouldbeineventtimerange",
    Endtime: "ValidationService.End time",
    shouldbegreaterthanstarttime:
      "ValidationService.shouldbegreaterthanstarttime",
    Pleaseentervalidgoal: "ValidationService.Pleaseentervalidgoal",
    invalidYouTube: "ValidationService.invalidYouTube",
    invalidAccountNumber: "ValidationService.invalidAccountNumber",
    invalidEmployeeId: "ValidationService.invalidEmployeeId",
    specialCharacter: "ValidationService.specialCharacter",
  };
}
