import { Injectable } from '@angular/core';
import {
      CanActivate,
      ActivatedRouteSnapshot,
      RouterStateSnapshot,
      UrlTree,
      Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PROFILE_TYPE, USER_ROLES } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { UserService } from 'src/app/services/user.service';

@Injectable({
      providedIn: 'root',
})
export class UserConsoleGuard implements CanActivate {
      constructor(
            private router: Router,
            private _appService: AppService,
            private userService: UserService,
      ) {}
      canActivate(
            route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot,
      ):
            | Observable<boolean | UrlTree>
            | Promise<boolean | UrlTree>
            | boolean
            | UrlTree {
            if (
                  this._appService.currentProfileType === PROFILE_TYPE.LIVINGROOM &&
                  this._appService.isDetailCompleted
            ) {
                  return true;
            }
            if (
                  this._appService.token &&
                  !this._appService.currentProfileType &&
                  this._appService.user?.role?.name != USER_ROLES.ADMIN
            ) {
                  setTimeout(() => {
                        this.userService.userTypeSelectionDialog();
                  }, 1000);
                  return false;
            }
            if (
                  this._appService.user?.id &&
                  !this._appService.isDetailCompleted &&
                  this._appService.user?.role?.name != USER_ROLES.ADMIN
            ) {
                  setTimeout(() => {
                        this.userService.UserInfoComponent();
                  }, 1000);
                  return false;
            }
            this._appService.clearLocalStorage();
            this.router.navigate(['public']);
            return false;
      }
}
