// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  env: "dev",
  panel: "user-dev",
  production: false,
  apiUrls: {
    // api: "https://beta2.couchvibes.com",
    api: "https://showcase.streamrebels.live",
  },
  socketUrl: "https://showcase.streamrebels.live/api/join",
  // socketUrl: "https://beta2.couchvibes.com/api/join",
  facebook: {
    client_id: "214603974411990",
    client_secret: "7ccd103b52a37271f87e584a8c8d379d",
  },

  agora: {
    appId: "f68f7d6c899a434bb351b94dc8ec7c56",
  },
  google: {
    apiKey: "AIzaSyCPaWZ82O2KbXCsZwnZXq0Qe_kKu-SL_WA", // couch-vibes
  },
  firebaseConfig: {
    apiKey: "AIzaSyCJNcJCYLzdtlaPGyeysZcpY_j5ljcwojc",
    authDomain: "couch-vibes-34d69.firebaseapp.com",
    databaseURL: "https://couch-vibes-34d69-default-rtdb.firebaseio.com",
    projectId: "couch-vibes-34d69",
    storageBucket: "couch-vibes-34d69.appspot.com",
    messagingSenderId: "886353294166",
    appId: "1:886353294166:web:dbf60582a9504d0aa91cf7",
    measurementId: "G-JQTZDXT9EL",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
