import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CvFormFieldModule } from '../cv-form-field/cv-form-field.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { GoogleSearchLocationDirective } from './directives/search-location/google-search-location.directive';
import { AgmOverlays } from "agm-overlays";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CvFormFieldModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google.apiKey,
      libraries: ['places', 'drawing', 'geometry']
    }),
    AgmOverlays,
  ],
  declarations: [GoogleSearchLocationDirective],
  exports: [
    GoogleSearchLocationDirective,
    AgmCoreModule,
    AgmOverlays,
  ]
})
export class SearchLocationAutocompleteModule { }
