import { Injectable } from '@angular/core';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService {

  hasNetworkConnection: boolean = true;
  status: string = 'ONLINE';

  get isConnected() {
    return (this.hasNetworkConnection);
  }

  constructor(
  ) {
    this.monitor().subscribe(connection => {
      this.hasNetworkConnection = connection;
      if (connection) {
        this.status = 'ONLINE';
      } else {
        this.status = 'OFFLINE';
      }
    });
  }

  monitor() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

}
