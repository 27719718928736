import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;
    const token = window.localStorage.getItem("token");
    if (token) {
      request = request.clone({
        setHeaders: { "x-access-token": token },
      });
    }
    return next.handle(request).pipe(
      tap(
        (event) => (ok = event instanceof HttpResponse ? "succeeded" : ""),
        (error) => (ok = "failed")
      ),
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${request.method} "${request.urlWithParams}"
           ${ok} in ${elapsed} ms.`;
      })
    );
  }
}
