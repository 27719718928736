import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class FirebaseMessagingService {

  currentMessage = new BehaviorSubject(null);
  deviceId: string | null = "string";

  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.requestPermission();
    this.receiveMessage();
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => { this.deviceId = token || "string"; },
      (err) => { console.error("Unable to get permission to notify.", err); }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      new Notification(payload.notification.title, {
        body: payload.notification.body,
      });
      this.currentMessage.next(payload);
      setTimeout(() => {
        this.currentMessage.next(null);
      }, 6000)
    }, console.error);
  }

}
