import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CvFormFieldComponent } from "./cv-form-field.component";
import { InputFieldDirective } from "./input-field/input-field.directive";
import { FieldAppendDirective } from "./field-append/field-append.directive";
import { FieldPrefixDirective } from "./field-prefix/field-prefix.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  CV_FORM_FIELD_OPTIONS,
  FormFieldOptions,
} from "./cv-form-field.constants";
import { CommonSharedModule } from "../../common-shared/common-shared.module";

const EXPORT_COMPONENTS = [
  CvFormFieldComponent,
  InputFieldDirective,
  FieldAppendDirective,
  FieldPrefixDirective,
];

const COMPONENTS = [...EXPORT_COMPONENTS];

@NgModule({
  imports: [CommonModule, FormsModule, CommonSharedModule, ReactiveFormsModule],
  declarations: [COMPONENTS],
  exports: [FormsModule, ReactiveFormsModule, EXPORT_COMPONENTS],
  entryComponents: [EXPORT_COMPONENTS],
})
export class CvFormFieldModule {
  static forRoot(
    options?: FormFieldOptions
  ): ModuleWithProviders<CvFormFieldModule> {
    return {
      ngModule: CvFormFieldModule,
      providers: [
        CvFormFieldModule,
        { provide: CV_FORM_FIELD_OPTIONS, useValue: options },
      ],
    };
  }
}
