import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TosterComponent } from "../components/toster/toster.component";
import { PROFILE_TYPE, User, USER_ROLES } from "../models/user.model";

@Injectable({
  providedIn: "root",
})
export class AppService {
  static currencySymbol: any;

  constructor(public router: Router, private _snackBar: MatSnackBar) {
    AppService.currencySymbol = this.user?.currencySymbol;
  }

  openToster(text: string | any) {
    this?._snackBar?.openFromComponent(TosterComponent, {
      data: { text },
      duration: 3000,
    });
  }

  clearLocalStorage() {
    this.router.navigate(["public/page"]);
    localStorage.clear();
  }

  valueToBeUpdate(newObject: any, oldObject: any) {
    let updatedObject: any = {};
    Object.keys(newObject).forEach((k) => {
      if (oldObject[k]) {
        if (typeof newObject[k] == "object") {
          if (JSON.stringify(newObject[k]) !== JSON.stringify(oldObject[k])) {
            updatedObject[k] = newObject[k];
          }
        } else {
          if (newObject[k] !== oldObject[k]) {
            updatedObject[k] = newObject[k];
          }
        }
      } else {
        updatedObject[k] = newObject[k];
      }
    });
    return updatedObject;
  }

  isObjectEmpty(object: any) {
    for (let obj in object) {
      if (object[obj]) {
        return false;
      }
    }
    return true;
  }

  get isDetailCompleted() {
    if (this.isEventManager) return this.user?.isEventManagerProfileComplete;
    return this.user?.isLivingRoomProfileComplete;
  }

  get isEventManager() {
    return this.user?.currentProfile?.profileType === PROFILE_TYPE.EVENTMANAGER;
  }

  get isUser() {
    return this.user?.currentProfile?.profileType === PROFILE_TYPE.LIVINGROOM;
  }

  get isAdmin() {
    return this.user?.role?.name === USER_ROLES.ADMIN;
  }

  get isSubstitute() {
    return this.user?.currentProfile?.profileType === PROFILE_TYPE.SUBSTITUTE;
  }

  get currentProfileType(): PROFILE_TYPE | null {
    return this.user?.currentProfile?.profileType || null;
  }

  get subscription(){
   return  this.user?.userSubscription?.subscription
  }

  get subscriptionType(){
    return this.user?.userSubscription?.subscriptionType
  }

  set user(u: User | null) {
    localStorage.setItem("user", JSON.stringify(u));
  }

  get user(): User | null {
    const user = localStorage.getItem("user");
    if (!user) return null;
    return JSON.parse(user) as User;
  }

  get token() {
    return localStorage.getItem("token");
  }

  set token(t) {
    localStorage.setItem("token", t!);
  }

  preventClick(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }
}
