<button cvTransparentButton class="back" (click)="goBack()">
  <img class="mr-2" src="assets/images/ic_back.svg" alt="" />
  {{ localization.backTxt | translate }}
</button>

<div class="container">
  <div class="content">
    <cv-card class="w-100 h-100 pb-0">
      <p class="cv-card-title mb-3">
        {{ localization.addDetailsTxt | translate }}
      </p>

      <p class="txt15-gry074-rgulr mb-4">
        {{ localization.description | translate }} <br />
        {{ localization.asTxt | translate }} {{ userRole }}
      </p>

      <form [formGroup]="formGroup" autocomplete="off">
        <!-- Artist name -->
        <div class="d-flex">
          <div class="col mr-2">
            <cv-form-field>
              <img cv-prefix src="assets/images/user-name.svg" alt="" />
              <input
                type="text"
                maxlength="60"
                name="Nick Name"
                placeholder="Nick Name"
                formControlName="nickName"
                cvInput
              />
            </cv-form-field>
          </div>
        </div>

        <div class="mb-4">
          <cv-form-field>
            <img
              cv-prefix
              [class.align-self-baseline]="appService.isUser"
              width="10px"
              style="margin-top:2px;"
              src="assets/images/location-outline.svg"
            />
            <input
              cvInput
              *ngIf="!appService.isUser"
              (input)="clearLocation()"
              placeholder="{{ localization.inputLocation | translate }}"
              [readOnly]="appService?.user?.location"
              (onLocationChange)="onLocationChange($event)"
              [name]="localization.Location | translate"
              googleSearchLocation
              formControlName="location"
              type="text"
            />

            <textarea
              *ngIf="appService.isUser"
              cvInput
              (input)="clearLocation()"
              placeholder="{{ localization.inputLocation | translate }}"
              [readOnly]="appService?.user?.location"
              (onLocationChange)="onLocationChange($event)"
              [name]="localization.Location | translate"
              googleSearchLocation
              formControlName="location"
              type="text"
            ></textarea>
          </cv-form-field>
        </div>

        <div class="d-flex" *ngIf="!appService.isUser">
          <div class="col-7 mr-3">
            <cv-form-field mode="select">
              <img cv-prefix src="assets/images/genre.svg" />
              <mat-select
                formControlName="eventCategoryIds"
                panelClass="black-mat-select"
                placeholder="{{ localization.inputGenre | translate }}"
                [name]="localization.Genre | translate"
                cvInput
                multiple
              >
                <mat-option
                  *ngFor="let item of categoryEvents?.items"
                  [value]="item?.id"
                >
                  {{ item?.name }}
                </mat-option>
              </mat-select>
            </cv-form-field>
          </div>

          <div class="col">
            <cv-form-field>
              <img class="cursor" cv-prefix src="assets/images/cake-dark.svg" alt="" />
              <input
                matInput
                [matDatepicker]="datepicker"
                readonly="true"
                (click)="datepicker.open()"
                [name]="'date of birth'"
                [max]="today"
                placeholder="Date of birth"
                formControlName="dateOfBirth"
                cvInput
              />
              <mat-datepicker #datepicker></mat-datepicker>
            </cv-form-field>
          </div>
        </div>
        <!-- startcheckbox -->
        <section class="white flex-col text-left font-15">
          Do you want to show location in profile and event?
          <mat-radio-group formControlName="showLocation" class="mt-3">
            <mat-radio-button [value]="true"> Yes </mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">
              No
            </mat-radio-button>
          </mat-radio-group>
        </section>
        <!-- end checkbox -->
        <div class="actions">
          <button
            orangeGradientBtn
            [isLoading]="isLoading"
            (click)="update()"
            type="submit"
          >
            {{ localization.continueTxt | translate }}
          </button>
        </div>

        <div class="step-text mt-5">Step 4 of 4</div>
      </form>
    </cv-card>
  </div>
</div>
