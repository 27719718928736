import { Component, OnInit } from '@angular/core';
import { PROFILE_TYPE } from 'src/app/models/user.model';
import { AppService } from 'src/app/services/app.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserInfoComponent } from '../user-info/user-info.component';

@Component({
      selector: 'user-type-selection',
      templateUrl: './user-type-selection.component.html',
      styleUrls: ['./user-type-selection.component.scss'],
})
export class UserTypeSelectionComponent implements OnInit {
      type: Array<PROFILE_TYPE> = [];
      PROFILE_TYPE = PROFILE_TYPE;

      constructor(
            private _userService: UserService,
            private _appService: AppService,
            public dialog: MatDialog,
            public dialogRef: MatDialogRef<UserTypeSelectionComponent>,
      ) {
            dialogRef.addPanelClass('backdrop-black');
      }

      ngOnInit() {}

      goBack() {
            this._userService.logOut();
            this.dialogRef.close(true);
      }

      selectAndSubmit(t: PROFILE_TYPE) {
            if (!this.type.includes(t)) {
                  this.type.push(t);
            } else {
                  const findIndex = this.type.findIndex((d: any) => {
                        return t == d;
                  });
                  this.type.splice(findIndex, 1);
            }
      }

      userInfo() {
            this.dialogRef.close(true);
            const currentProfile = {
                  currentProfile: {
                        profileType: this.type.includes(
                              PROFILE_TYPE.EVENTMANAGER,
                        )
                              ? PROFILE_TYPE.EVENTMANAGER
                              : PROFILE_TYPE.LIVINGROOM,
                  },
            };
            this._appService.user = {
                  ...(this._appService.user as any),
                  ...currentProfile,
            };
            this.dialog.open(UserInfoComponent, {
                  data: { profileTypes: this.type },
                  panelClass: 'noPadding',
                  autoFocus: false,
            });
      }

      get isManager() {
            return this.type?.includes(PROFILE_TYPE.EVENTMANAGER);
      }

      get isLivingRoom() {
            return this.type?.includes(PROFILE_TYPE.LIVINGROOM);
      }

      localization = {
            backTxt: 'UserTypeSelectionComponent.backTxt',
            whoAreYou: 'UserTypeSelectionComponent.whoAreYou',
            livingRoom: 'UserTypeSelectionComponent.livingRoom',
            livingRoomSubTitle: 'UserTypeSelectionComponent.livingRoomSubTitle',
            Artist: 'UserTypeSelectionComponent.Artist',
            artistSubTitle: 'UserTypeSelectionComponent.artistSubTitle',
            eventManger: 'UserTypeSelectionComponent.eventManger',
            eventOrgTxt: 'UserTypeSelectionComponent.eventOrgTxt',
      };
}
