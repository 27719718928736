import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AUTH_ROUTES } from './modules/auth/auth-constants';
import { ManagerConsoleGuard } from './modules/manager-console/guards/manager-console.guard';
import { MANAGER_ROUTES } from './modules/manager-console/manager-constant';
import { UserConsoleGuard } from './modules/user-console/guards/user-console.guards';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

const adminRoutes = [
      { path: '', redirectTo: '', pathMatch: 'full' },
      {
            path: '',
            loadChildren: () =>
                  import('./modules/admin-console/admin-console.module').then(
                        (m) => m.AdminConsoleModule,
                  ),
      },
      { path: '**', redirectTo: '' },
];

const userRoutes: Routes = [
      {
            path: MANAGER_ROUTES.MAIN,
            loadChildren: () =>
                  import(
                        './modules/manager-console/manager-console.module'
                  ).then((m) => m.ManagerConsoleModule),
            canActivate: [AuthGuard, ManagerConsoleGuard],
      },
      {
            path: 'user',
            loadChildren: () =>
                  import('./modules/user-console/user-console.module').then(
                        (m) => m.UserConsoleModule,
                  ),
            canActivate: [AuthGuard, UserConsoleGuard],
      },
      {
            path: AUTH_ROUTES.MAIN,
            loadChildren: () =>
                  import('./modules/auth/auth.module').then(
                        (m) => m.AuthModule,
                  ),
      },
      {
            path: 'public',
            loadChildren: () =>
                  import('./modules/public-console/landing-page.module').then(
                        (m) => m.PublicModule,
                  ),
            canActivate: [LoginGuard],
      },
      { path: '**', redirectTo: MANAGER_ROUTES.MAIN },
];

const routes = environment.panel.startsWith('admin') ? adminRoutes : userRoutes;

@NgModule({
      imports: [
            RouterModule.forRoot(routes, {
                  preloadingStrategy: PreloadAllModules,
                  onSameUrlNavigation: 'reload',
            }),
      ],
      exports: [RouterModule],
})
export class AppRoutingModule { }
